import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';

// 创建入库单
export function createStorageBill(){
    return request('/storeStorage/createStorageBill.do',"POST",{userId:SystemUtils.loginUser.id,userName:SystemUtils.loginUser.name,userAccount:SystemUtils.loginUser.account})
}

// 根据入库类型获取任务单或采购单信息
export function getProjectStage(data){
    return request('/storeStorage/getProjectStage.do',"POST",data)
}

// 提交入库单
export function storagePublish(data){
    return request('/storeStorage/storagePublish.do',"POST",data)
}
// 更新入库单信息，获取入库明细
export function getStorageItem(data){
    return request('/storeStorage/getStorageItem.do',"POST",Object.assign(data,{userId:SystemUtils.loginUser.id,userName:SystemUtils.loginUser.name,userAccount:SystemUtils.loginUser.account}))
}
// 生成零件二维码
export function createTwoCode(data){
    return request('/storeStorage/createTwoCode.do',"POST",data)
}
// 保存入库明细(自制件入库用)
export function doSavePartBySelect(data){
    return request('/storeStorage/doSavePartBySelect.do',"POST",data)
}

// 自制件入库选择零件
export function getPartBySelect(data){
    return request('/storeStorage/getPartBySelect.do',"POST",data)
}
// 删除入库明细
export function deletePartBySelect(data){
    return request('/storeStorage/deletePartBySelect.do',"POST",data)
}
// 删除入库明细
export function getStorageBillInfo(data){
    return request('/storeStorage/getStorageBillInfo.do',"POST",Object.assign(data,{userId:SystemUtils.loginUser.id}))
}
